const slotFrontRulesKeys = {
    'SLOT-ITEM-BOATS': 'slot-item-boats',
    'SLOT-ITEM-TABS-GENERAL': 'slot-item-tabs-general',
    'SLOT-ITEM-TABS-PROMOS': 'slot-item-tabs-promos',
    'SLOT-ITEM-TABS-INSTRUCTORS': 'slot-item-tabs-instructors',
    'SLOT-ITEM-ORDERS-CREATION': 'slot-item-orders-creation',
    'SLOT-ITEM-ORDERS-LIST-ROW-EDIT': 'slot-item-orders-list-row-edit',
    'SLOT-ITEM-ORDERS-LIST-ROW-REMOVE': 'slot-item-orders-list-row-remove',
    'SLOT-ITEM-ORDERS-LIST-ROW-TRANSFER': 'slot-item-orders-list-row-transfer',
}

const slotFrontRulesArray = [
    { key: slotFrontRulesKeys['SLOT-ITEM-BOATS'], label: 'frontRoles.slot.item.boats' },
    { key: slotFrontRulesKeys['SLOT-ITEM-TABS-GENERAL'], label: 'frontRoles.slot.item.tabs.general' },
    { key: slotFrontRulesKeys['SLOT-ITEM-TABS-PROMOS'], label: 'frontRoles.slot.item.tabs.promos' },
    { key: slotFrontRulesKeys['SLOT-ITEM-TABS-INSTRUCTORS'], label: 'frontRoles.slot.item.tabs.instructors' },
    { key: slotFrontRulesKeys['SLOT-ITEM-ORDERS-CREATION'], label: 'frontRoles.slot.item.orders.creation' },
    { key: slotFrontRulesKeys['SLOT-ITEM-ORDERS-LIST-ROW-EDIT'], label: 'frontRoles.slot.item.orders.list.row.edit' },
    { key: slotFrontRulesKeys['SLOT-ITEM-ORDERS-LIST-ROW-REMOVE'], label: 'frontRoles.slot.item.orders.list.row.remove' },
    { key: slotFrontRulesKeys['SLOT-ITEM-ORDERS-LIST-ROW-TRANSFER'], label: 'frontRoles.slot.item.orders.list.row.transfer' },
]

module.exports = {
    slotFrontRulesKeys,
    slotFrontRulesArray
}
const chartersFrontRulesKeys = {
    'CHARTERS-LIST-ITEM-REMOVE': 'charters-list-item-remove',
    'CHARTERS-ITEM-ARRIVALS-LIST-ROW-REMOVE': 'charters-item-arrivals-list-row-remove',
}

const chartersFrontRulesArray = [
    { key: chartersFrontRulesKeys['CHARTERS-LIST-ITEM-REMOVE'], label: 'frontRoles.charter.list.item.remove' },
    { key: chartersFrontRulesKeys['CHARTERS-ITEM-ARRIVALS-LIST-ROW-REMOVE'], label: 'frontRoles.charter.item.arrivals.list.row.remove' },
]

module.exports = {
    chartersFrontRulesKeys,
    chartersFrontRulesArray
}
const theoryFrontRulesKeys = {
    'THEORY-LIST-ROW-REMOVE': 'theory-list-row-remove',
}

const theoryFrontRulesArray = [
    { key: theoryFrontRulesKeys['THEORY-LIST-ROW-REMOVE'], label: 'frontRoles.theory.list.row.remove' },
]

module.exports = {
    theoryFrontRulesKeys,
    theoryFrontRulesArray
}
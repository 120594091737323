const practiseFrontRulesKeys = {
    'PRACTISE-LIST-ITEM-REMOVE': 'practise-list-item-remove',
    'PRACTISE-ITEM-ARRIVALS-LIST-ROW-REMOVE': 'practise-item-arrivals-list-row-remove',
}

const practiseFrontRulesArray = [
    { key: practiseFrontRulesKeys['PRACTISE-LIST-ITEM-REMOVE'], label: 'frontRoles.practise.list.item.remove' },
    { key: practiseFrontRulesKeys['PRACTISE-ITEM-ARRIVALS-LIST-ROW-REMOVE'], label: 'frontRoles.practise.item.arrivals.list.row.remove' },
]

module.exports = {
    practiseFrontRulesKeys,
    practiseFrontRulesArray
}
const adventuresFrontRulesKeys = {
    'ADVENTURES-LIST-ITEM-REMOVE': 'adventures-list-item-remove',
    'ADVENTURES-ITEM-ARRIVALS-LIST-ROW-REMOVE': 'adventures-item-arrivals-list-row-remove',
}

const adventuresFrontRulesArray = [
    { key: adventuresFrontRulesKeys['ADVENTURES-LIST-ITEM-REMOVE'], label: 'frontRoles.adventures.list.item.remove' },
    { key: adventuresFrontRulesKeys['ADVENTURES-ITEM-ARRIVALS-LIST-ROW-REMOVE'], label: 'frontRoles.adventures.item.arrivals.list.row.remove' },
]

module.exports = {
    adventuresFrontRulesKeys,
    adventuresFrontRulesArray
}
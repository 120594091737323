const {breadcrumbsFrontRulesArray, breadcrumbsFrontRulesKeys} = require("./breadcrubms");
const {basesFrontRulesKeys, basesFrontRulesArray} = require("./bases");
const {baseFrontRulesKeys, baseFrontRulesArray} = require("./base");
const {eventFrontRulesKeys, eventFrontRulesArray} = require("./event");
const {slotFrontRulesKeys, slotFrontRulesArray} = require("./slot");
const {sidebarFrontRulesKeys, sidebarFrontRulesArray} = require("./sidebar");
const {practiseFrontRulesKeys, practiseFrontRulesArray} = require("./practise");
const {adventuresFrontRulesArray, adventuresFrontRulesKeys} = require("./adventures");
const {theoryFrontRulesKeys, theoryFrontRulesArray} = require("./theory");
const {lecturesFrontRulesKeys, lecturesFrontRulesArray} = require("./lectures");
const {certificatesFrontRulesKeys, certificatesFrontRulesArray} = require("./certificates");
const {promocodesFrontRulesKeys, promocodesFrontRulesArray} = require("./promocodes");
const {chartersFrontRulesKeys, chartersFrontRulesArray} = require("./charters");

const frontRulesKeys= {
    ...breadcrumbsFrontRulesKeys,
    ...basesFrontRulesKeys,
    ...baseFrontRulesKeys,
    ...eventFrontRulesKeys,
    ...slotFrontRulesKeys,
    ...sidebarFrontRulesKeys,
    ...practiseFrontRulesKeys,
    ...adventuresFrontRulesKeys,
    ...theoryFrontRulesKeys,
    ...lecturesFrontRulesKeys,
    ...certificatesFrontRulesKeys,
    ...promocodesFrontRulesKeys,
    ...chartersFrontRulesKeys
}

const frk= frontRulesKeys;

// Array with access keys
const frontRulesArray = [
    // Under discussion
    // { key: 'deposits', label: 'frontRoles.deposits' },
    // { key: 'sidebar-bases', label: 'frontRoles.sidebar.bases' },

    ...breadcrumbsFrontRulesArray,
    ...basesFrontRulesArray,
    ...baseFrontRulesArray,
    ...eventFrontRulesArray,
    ...slotFrontRulesArray,
    ...sidebarFrontRulesArray,
    ...practiseFrontRulesArray,
    ...adventuresFrontRulesArray,
    ...theoryFrontRulesArray,
    ...lecturesFrontRulesArray,
    ...certificatesFrontRulesArray,
    ...promocodesFrontRulesArray,
    ...chartersFrontRulesArray,
];

const frontRulesDefaultObject = Object.fromEntries(frontRulesArray.map(i => [i.key, false]));

module.exports = {
    frontRulesArray,
    frontRulesDefaultObject,
    frontRulesKeys,
    frk,
}
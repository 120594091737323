const eventFrontRulesKeys = {
    'EVENT-ITEM-EDIT': 'event-item-edit',
    'EVENT-ITEM-EXPORT': 'event-item-export',
    'EVENT-ITEM-SLOTS-CREATION': 'event-item-slots-creation',
    'EVENT-ITEM-SLOTS-LIST-ROW-REMOVE': 'event-item-slots-list-row-remove',
}

const eventFrontRulesArray = [
    { key: eventFrontRulesKeys['EVENT-ITEM-EDIT'], label: 'frontRoles.event.item.edit' },
    { key: eventFrontRulesKeys['EVENT-ITEM-EXPORT'], label: 'frontRoles.event.item.export' },
    { key: eventFrontRulesKeys['EVENT-ITEM-SLOTS-CREATION'], label: 'frontRoles.event.item.slots.creation' },
    { key: eventFrontRulesKeys['EVENT-ITEM-SLOTS-LIST-ROW-REMOVE'], label: 'frontRoles.event.item.slots.list.row.remove' },
]

module.exports = {
    eventFrontRulesKeys,
    eventFrontRulesArray
}
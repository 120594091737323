export const CHECK_TYPES = {
    SPORT: 'sport',
    SPORT_CAMP: 'sport_camp',
    SCHOOL: 'school',
    TOURISM: 'tourism',
    CERTIFICATE: 'certificate',
    CORPORATE: 'corporate',
    SHOP: 'shop',
    SPORT_HOTEL: 'sport_hotel',
}

export const RU_EVENT_TYPES = {
    [CHECK_TYPES.SPORT]: 'Спорт',
    [CHECK_TYPES.SPORT_CAMP]: 'Спорт лагерь',
    [CHECK_TYPES.SCHOOL]: 'Школа',
    [CHECK_TYPES.TOURISM]: 'Туризм',
    [CHECK_TYPES.CERTIFICATE]: 'Сертификат',
    [CHECK_TYPES.CORPORATE]: 'Корпоративы',
    [CHECK_TYPES.SHOP]: 'Магазин',
    [CHECK_TYPES.SPORT_HOTEL]: 'Спорт-отель',
}

export const EN_EVENT_TYPES = {
    [CHECK_TYPES.SPORT]: 'Sport',
    [CHECK_TYPES.SPORT_CAMP]: 'Sport camp',
    [CHECK_TYPES.SCHOOL]: 'School',
    [CHECK_TYPES.TOURISM]: 'Tourism',
    [CHECK_TYPES.CERTIFICATE]: 'Certificate',
    [CHECK_TYPES.CORPORATE]: 'Corporates',
    [CHECK_TYPES.SHOP]: 'Shop',
    [CHECK_TYPES.SPORT_HOTEL]: 'Sport-hotel',
}
const promocodesFrontRulesKeys = {
  'PROMOCODES-ITEM-EDIT': 'promocodes-item-edit'
}

const promocodesFrontRulesArray = [
  { key: promocodesFrontRulesKeys['PROMOCODES-ITEM-EDIT'], label: 'frontRoles.promocodes.item.edit' }
]

module.exports = {
  promocodesFrontRulesKeys,
  promocodesFrontRulesArray
}
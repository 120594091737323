const CORP_PAGE_PARAMETERS = {
    START_DATE: 'start_date',
    START_TIME: 1,
    END_TIME: 1,
    INSTRUCTOR_NAME: 1,
    RULES_LINK: 1,
    PHOTOS_LINK: 1,
    GETTING_THERE_LINK: 'base_address.getting_there_link',
    COORDINATOR_NAME: 'coordinator.name',
    COORDINATOR_PHONE: 'coordinator.phone',
    COORDINATOR_CORP_PHONE: 'coordinator.corp_phone',
    COORDINATOR_PHOTO: 'coordinator.img.at(-1).url',
    MORE_LINK: 1,
    PHONE: 1,
    CARD_TEMPLATE: 1,
    LOGO: 'logo.url',
    WHAT_BRING: 'what_bring',
    PROMOCODE: 1,
    SHOW_PHOTO: 'show_photo',
    SHOW_DISCOUNTS: 'show_discounts',
    SHOW_BLOCK_INFORMATION: 'show_block_information',
    SHOW_BLOCK_INFORMATION: 'show_block_information',
    YA_MAPS_WIDGET: 1,
    EVENT_FORMAT: 'event_format',
    DESCRIPTION: 'description',
    DURATION: 'duration',
    TIMING: 'timing',
    AREAS: 'areas',
    FORMATS: 'formats',
    BASE_ADDRESSES: 'base_addresses',
    AUTH_BUTTON_LINK: 0,
}

module.exports = {
    CORP_PAGE_PARAMETERS
}
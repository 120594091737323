const CHILDREN_BASES = ['Детский клуб Силы ветра в Строгино', 'Остров мечты (дети)'];

const COUNTRIES = {
    'RU': 'Россия',
    'EN': 'UAE',
}

const COUNTRIES_LIST = [
    {
        key: 'RU',
        value: COUNTRIES.RU
    },
    {
        key: 'EN',
        value: COUNTRIES.EN
    }
]

const INT_COUNTRIES_LIST = {
    RU: [
        {
            key: 'RU',
            value: COUNTRIES.RU
        }
    ],
    EN: [
        {
            key: 'EN',
            value: COUNTRIES.EN
        }
    ]
}
module.exports = {CHILDREN_BASES, COUNTRIES, COUNTRIES_LIST, INT_COUNTRIES_LIST};
const baseFrontRulesKeys = {
    'BASE-EVENTS-EXPORT': 'base-events-export',
    'BASE-EVENTS-CREATION': 'base-events-creation',
    'BASE-SLOTS-LIST-ROW-HIDE': 'base-slots-list-row-hide',
    'BASE-SLOTS-LIST-ROW-BOATS': 'base-slots-list-row-boats',
    'BASE-SLOTS-LIST-ROW-CLOSE': 'base-slots-list-row-close',
    'BASE-SLOTS-LIST-ROW-REMOVE': 'base-slots-list-row-remove',
    'BASE-SLOTS-LIST-ROW-INSTRUCTORS': 'base-slots-list-row-instructors',
}

const baseFrontRulesArray = [
    { key: baseFrontRulesKeys['BASE-EVENTS-EXPORT'], label: 'frontRoles.base.events.export' },
    { key: baseFrontRulesKeys['BASE-EVENTS-CREATION'], label: 'frontRoles.base.events.creation' },
    { key: baseFrontRulesKeys['BASE-SLOTS-LIST-ROW-HIDE'], label: 'frontRoles.base.slots.list.row.hide' },
    { key: baseFrontRulesKeys['BASE-SLOTS-LIST-ROW-BOATS'], label: 'frontRoles.base.slots.list.row.boats' },
    { key: baseFrontRulesKeys['BASE-SLOTS-LIST-ROW-CLOSE'], label: 'frontRoles.base.slots.list.row.close' },
    { key: baseFrontRulesKeys['BASE-SLOTS-LIST-ROW-REMOVE'], label: 'frontRoles.base.slots.list.row.remove' },
    { key: baseFrontRulesKeys['BASE-SLOTS-LIST-ROW-INSTRUCTORS'], label: 'frontRoles.base.slots.list.row.instructors' },
]

module.exports = {
    baseFrontRulesKeys,
    baseFrontRulesArray
}
const lecturesFrontRulesKeys = {
    'LECTURE-LIST-ROW-REMOVE': 'lecture-list-row-remove',
}

const lecturesFrontRulesArray = [
    { key: lecturesFrontRulesKeys['LECTURE-LIST-ROW-REMOVE'], label: 'frontRoles.lecture.list.row.remove' },
]

module.exports = {
    lecturesFrontRulesKeys,
    lecturesFrontRulesArray
}
const breadcrumbsFrontRulesKeys = {
    'BREADCRUMBS-BASES': 'breadcrumbs-bases',
}

const breadcrumbsFrontRulesArray = [
    { key: breadcrumbsFrontRulesKeys['BREADCRUMBS-BASES'], label: 'frontRoles.breadcrumbs.bases' },
]

module.exports = {
    breadcrumbsFrontRulesKeys,
    breadcrumbsFrontRulesArray
}
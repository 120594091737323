const sidebarFrontRulesKeys = {
    'SIDEBAR-BASES': 'sidebar-bases',
    'SIDEBAR-CHARTER': 'sidebar-charter',
    'SIDEBAR-SCHOOL': 'sidebar-school',
    'SIDEBAR-ADVENTURES': 'sidebar-adventures',
    'SIDEBAR-CONSTRUCTOR': 'sidebar-constructor',
    'SIDEBAR-CAMPS-WORLD': 'sidebar-camps-world',
    'SIDEBAR-CAMPS-RUSSIA': 'sidebar-camps-russia',
    'SIDEBAR-MANAGEMENT-USERS': 'sidebar-management-users',
    'SIDEBAR-MANAGEMENT-ORDERS': 'sidebar-management-orders',
    'SIDEBAR-MANAGEMENT-PROMOS': 'sidebar-management-promos',
    'SIDEBAR-MANAGEMENT-REVIEWS': 'sidebar-management-reviews',
    'SIDEBAR-MANAGEMENT-EXPORTS': 'sidebar-management-exports',
    'SIDEBAR-MANAGEMENT-BLACKLIST': 'sidebar-management-blacklist',
    'SIDEBAR-MANAGEMENT-MATERIALS': 'sidebar-management-materials',
    'SIDEBAR-MANAGEMENT-INSTRUCTORS': 'sidebar-management-instructors',
    'SIDEBAR-MANAGEMENT-CERTIFICATES': 'sidebar-management-certificates',
    'SIDEBAR-MANAGEMENT-PROMOPACKAGES': 'sidebar-management-promopackages',
    'SIDEBAR-MANAGEMENT-LINKSGENERATOR': 'sidebar-management-linksgenerator',
    'SIDEBAR-DIRECTORY-OFFERS': 'sidebar-directory-offers',
    'SIDEBAR-DIRECTORY-CHECKS': 'sidebar-directory-checks',
    'SIDEBAR-DIRECTORY-MERCHANTS': 'sidebar-directory-merchants',
    'SIDEBAR-DIRECTORY-CORPCLIENTS': 'sidebar-directory-corpclients',
    'SIDEBAR-DIRECTORY-TRANSSTATUS': 'sidebar-directory-transstatus',
    'SIDEBAR-DIRECTORY-BOATCLASSES': 'sidebar-directory-boatclasses',
    'SIDEBAR-DIRECTORY-TSHORTSIZES': 'sidebar-directory-tshortsizes',
    'SIDEBAR-DIRECTORY-CUSTOMFIELDS': 'sidebar-directory-customfields',
    'SIDEBAR-DIRECTORY-SUBSCRIPTIONS': 'sidebar-directory-subscriptions',
    'SIDEBAR-DIRECTORY-EXTRAMATERIALS': 'sidebar-directory-extramaterials',
    'SIDEBAR-DIRECTORY-DIFFICULTYSCALES': 'sidebar-directory-difficultyscales',
    'SIDEBAR-DIRECTORY-TEMPLATESCUSTOMFIELDS': 'sidebar-directory-templatescustomfields',
}

const sidebarFrontRulesArray = [
    { key: sidebarFrontRulesKeys['SIDEBAR-BASES'], label: 'frontRoles.sidebar.bases' },
    { key: sidebarFrontRulesKeys['SIDEBAR-CHARTER'], label: 'frontRoles.sidebar.charter' },
    { key: sidebarFrontRulesKeys['SIDEBAR-SCHOOL'], label: 'frontRoles.sidebar.school' },
    { key: sidebarFrontRulesKeys['SIDEBAR-ADVENTURES'], label: 'frontRoles.sidebar.adventures' },
    { key: sidebarFrontRulesKeys['SIDEBAR-CONSTRUCTOR'], label: 'frontRoles.sidebar.constructor' },
    { key: sidebarFrontRulesKeys['SIDEBAR-CAMPS-WORLD'], label: 'frontRoles.sidebar.camps.world' },
    { key: sidebarFrontRulesKeys['SIDEBAR-CAMPS-RUSSIA'], label: 'frontRoles.sidebar.camps.russia' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-USERS'], label: 'frontRoles.sidebar.management.users' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-ORDERS'], label: 'frontRoles.sidebar.management.orders' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-PROMOS'], label: 'frontRoles.sidebar.management.promos' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-REVIEWS'], label: 'frontRoles.sidebar.management.reviews' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-EXPORTS'], label: 'frontRoles.sidebar.management.exports' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-BLACKLIST'], label: 'frontRoles.sidebar.management.blacklist' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-MATERIALS'], label: 'frontRoles.sidebar.management.materials' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-INSTRUCTORS'], label: 'frontRoles.sidebar.management.instructors' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-CERTIFICATES'], label: 'frontRoles.sidebar.management.certificates' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-PROMOPACKAGES'], label: 'frontRoles.sidebar.management.promopackages' },
    { key: sidebarFrontRulesKeys['SIDEBAR-MANAGEMENT-LINKSGENERATOR'], label: 'frontRoles.sidebar.management.linksgenerator' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-OFFERS'], label: 'frontRoles.sidebar.directory.offers' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-CHECKS'], label: 'frontRoles.sidebar.directory.checks' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-MERCHANTS'], label: 'frontRoles.sidebar.directory.merchants' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-CORPCLIENTS'], label: 'frontRoles.sidebar.directory.corpclients' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-TRANSSTATUS'], label: 'frontRoles.sidebar.directory.transstatus' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-BOATCLASSES'], label: 'frontRoles.sidebar.directory.boatclasses' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-TSHORTSIZES'], label: 'frontRoles.sidebar.directory.tshortsizes' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-CUSTOMFIELDS'], label: 'frontRoles.sidebar.directory.customfields' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-SUBSCRIPTIONS'], label: 'frontRoles.sidebar.directory.subscriptions' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-EXTRAMATERIALS'], label: 'frontRoles.sidebar.directory.extramaterials' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-DIFFICULTYSCALES'], label: 'frontRoles.sidebar.directory.difficultyscales' },
    { key: sidebarFrontRulesKeys['SIDEBAR-DIRECTORY-TEMPLATESCUSTOMFIELDS'], label: 'frontRoles.sidebar.directory.templatescustomfields' },
]

module.exports = {
    sidebarFrontRulesKeys,
    sidebarFrontRulesArray
}
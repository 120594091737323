const basesFrontRulesKeys = {
    'BASES-LIST-ROW-GEAR': 'bases-list-row-gear',
    'BASES-ITEM-INSTRUCTORS': 'bases-item-instructors',
}

const basesFrontRulesArray = [
    { key: basesFrontRulesKeys['BASES-LIST-ROW-GEAR'], label: 'frontRoles.bases.list.row.gear' },
    { key: basesFrontRulesKeys['BASES-ITEM-INSTRUCTORS'], label: 'frontRoles.bases.item.instructors' },
]

module.exports = {
    basesFrontRulesKeys,
    basesFrontRulesArray
}
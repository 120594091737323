const certificatesFrontRulesKeys = {
    'CERTIFICATES-ITEM-TABS-HISTORY': 'certificates-item-tabs-history',
    'CERTIFICATES-ITEM-EDIT': 'certificates-item-edit',
}

const certificatesFrontRulesArray = [
    { key: certificatesFrontRulesKeys['CERTIFICATES-ITEM-TABS-HISTORY'], label: 'frontRoles.certificates.item.tab.history' },
    { key: certificatesFrontRulesKeys['CERTIFICATES-ITEM-EDIT'], label: 'frontRoles.certificates.item.edit' },
]

module.exports = {
    certificatesFrontRulesKeys,
    certificatesFrontRulesArray
}